import { isMobile as isMobileDetect } from "react-device-detect";
import LeadTypeButton from "./molecules/LeadTypeButton";
import PanelHeading from "./molecules/PanelHeading";
import PanelSpacer from "./molecules/PanelSpacer";
import Page from "./organisms/Page";
import styles from "./organisms/Page.module.scss";
import Panel from "./organisms/Panel";

import { StyleSheet, css } from "aphrodite";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createFunnelRequest } from "../../../config/Analytics";
import { ASSET_BASE_URL } from "../../../config/constants";
import { useExperiment } from "../../../contexts/ExperimentContext";
import ViewAgentsUserPrompt from "../../navigation/ViewAgentsUserPrompt";
import BuyerIcon from "../../svg/BuyerIcon";
import BuyerSellerIcon from "../../svg/BuyerSellerIcon";
import SellerIcon from "../../svg/SellerIcon";

export default function Borderless({
  agentTaxonomy,
  chosenLocale,
  setLeadType,
}) {
  const { t } = useTranslation(["translation", "common"]);
  const router = useRouter();

  const [isMobile, setIsMobile] = useState(false);
  const [windowWidth, setWindowWidth] = useState();

  useEffect(() => {
    setIsMobile(isMobileDetect);
  }, []);

  useEffect(() => {
    setWindowWidth(window.innerWidth);

    // Handler function to update the state with the new window width
    const handleResize = () => {
      let timeoutId;
      return () => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
          setWindowWidth(window.innerWidth);
        }, 250); // 250ms debounce delay
      };
    };

    const debouncedHandleResize = handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", debouncedHandleResize);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
  }, []);

  const { experiment } = useExperiment();

  const leadButtonClick = async (leadType) => {
    const parameters = { leadType };
    await createFunnelRequest({
      page: ASSET_BASE_URL + router.asPath.split("?")[0],
      request_type: "POST",
      parameters,
    });

    setLeadType(leadType);
  };

  const iconSize = 36;

  return (
    <>
      <ViewAgentsUserPrompt type={router.query.leadType} />
      <link
        rel="canonical"
        href={"https://www.realestateagents.com/compare-agents"}
      />
      <Page>
        <Panel
          className={[styles.Page__panel, styles.Page__panel_new_landing].join(
            " "
          )}
          panelContainerClassName={css(aphStyles.panel)}
          showProgressBar={false}
          large={false}
        >
          <div>
            <h1
              className={[
                styles["Page__heading"],
                styles["Page__heading_new"],
              ].join(" ")}
            >
              <div>
                {t(`headline_alternate${windowWidth < 1024 ? "_mobile" : ""}`, {
                  chosenLocale,
                  agent_taxonomy: agentTaxonomy,
                })}
              </div>{" "}
              {chosenLocale ? <br className={css(aphStyles.phones)} /> : null}
              {chosenLocale ? t("in") + " " + chosenLocale : t("for_you")}
            </h1>
            <div
              className={[
                styles["Page__sub-heading"],
                styles["Page__sub-heading-new"],
              ].join(" ")}
            >
              {t("signup02.rea.subheading")}
            </div>
          </div>

          {/* <div className={css(aphStyles.mobile)}>
            <PanelHeading center={true}>{t("signup02.rea.cta")}</PanelHeading>
          </div> */}
          {/* <PanelHeading center={true}>
            {t("signup02.rea.cta_desktop")}
          </PanelHeading> */}
          <div
            className={[
              styles["Page__button-grid"],
              styles["Page__button-grid-bigger"],
            ].join(" ")}
          >
            <LeadTypeButton
              className={styles.LeadTypeButtonNew}
              innerClassName={styles.LeadTypeButtonNewInner}
              id="buy-button"
              onClick={() => leadButtonClick("buyer")}
            >
              <BuyerIcon width={iconSize} height={iconSize} color="#027850" />
              <div className={styles.LeadTypeButtonText}>
                {t("signup02.rea.im_buying")}
              </div>
            </LeadTypeButton>
            <LeadTypeButton
              className={styles.LeadTypeButtonNew}
              innerClassName={styles.LeadTypeButtonNewInner}
              id="sell-button"
              onClick={() => leadButtonClick("seller")}
            >
              <SellerIcon width={iconSize} height={iconSize} color="#027850" />
              <div className={styles.LeadTypeButtonText}>
                {t("signup02.rea.im_selling")}
              </div>
            </LeadTypeButton>
            <LeadTypeButton
              className={styles.LeadTypeButtonNew}
              innerClassName={styles.LeadTypeButtonNewInner}
              id="both-button"
              onClick={() => leadButtonClick("both")}
            >
              <BuyerSellerIcon
                width={iconSize}
                height={iconSize}
                color="#027850"
              />
              <div className={styles.LeadTypeButtonText}>
                {t("signup02.rea.im_buying_and_selling")}
              </div>
            </LeadTypeButton>
          </div>
        </Panel>
        <ul className={css(aphStyles.list)}>
          {[1, 2, 3].map((number, index) => {
            return (
              <div className={css(aphStyles.item)} key={`heading_${number}`}>
                <div className={css(aphStyles.heading)}>
                  {t(`signup02.rea.benfits.benefit_${index + 1}_heading`)}
                </div>
                <div className={css(aphStyles.subHeading)}>
                  {t(`signup02.rea.benfits.benefit_${index + 1}_subheading`)}
                </div>
              </div>
            );
          })}
        </ul>
      </Page>
    </>
  );
}

const aphStyles = StyleSheet.create({
  phones: {
    "@media only screen and (min-width: 767px)": {
      display: "none",
    },
  },
  mobile: {
    "@media only screen and (min-width: 1024px)": {
      display: "none",
    },
  },
  desktop: {
    "@media only screen and (max-width: 1023px)": {
      display: "none",
    },
  },
  item: {
    textAlign: "center",
  },
  panel: {
    maxWidth: 620,
    paddingTop: 0,
    "@media only screen and (max-width: 1023px)": {
      minHeight: "unset",
      paddingTop: 24,
    },
  },
  list: {
    display: "flex",
    gap: 40,
    justifyContent: "center",
    paddingLeft: 0,
    // marginBottom: 16,

    "@media only screen and (max-width: 767px)": {
      flexDirection: "column",
      marginTop: 50,
    },
  },
  heading: {
    fontSize: 32,
    fontWeight: 600,
    color: "#027850",
    letterSpacing: "-0.64px",
    marginBottom: 12,
  },
  subHeading: {
    color: "#565D5A",
    letterSpacing: "-.28px",
    fontSize: 14,
    fontWeight: 500,
  },
});
